<template>
  <div>
    <p v-if="RetMsg">出现错误：{{RetMsg}}</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import storage from "../../common/utils/storage";
export default {
  name: 'Authorize',
  data() {
    return {
      RetMsg: ''
    }
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
    scope() {
      return this.$route.query.scope;
    }
  },
  created() {
    // 判断微信授权回来
    let url = window.location.href;
    if (url.indexOf("code") != -1) {
      if (url.substr(-11) == "#/authorize") {
        let url1Index = url.indexOf("?");
        let url1 = url.substring(0, url1Index);
        let url2 = url.substring(url1Index, url.length - 11);
        let url3 = url.substr(-11);
        let urlAll = url1.concat(url3, url2);
        window.location.href = urlAll;
      } else {
        this.state = this.$route.query.state;
        let code = this.$route.query.code;
        let scope = storage.getStore('scope');
        if (scope == 'snsapi_base') {
          this.$axios.post('/Api/Api/Web/GetWxUser/GeiUserSilence?code=' + code).then(res => {
            if (res.RetCode == '10000') {
              this.setOpenid(res.Data);
              sessionStorage.setItem("Openid", res.Data);
              // storage.setStore('Openid', res.Data);
              let lastPath = storage.getStore('lastPath');
              this.$router.replace(lastPath);
            } else {
              this.RetMsg = res.RetMsg;
              this.$toast(res.RetMsg);
            }
          })
        } else {
          this.$axios.post('/Api/Api/Web/GetWxUser/GetUserInfo?code=' + code).then(res => {
            if (res.RetCode == '10000') {
              if (res.Data) {
                sessionStorage.setItem("Openid", res.Data.Openid);
                this.setOpenid(res.Data.Openid);
                this.setUserInfo({userInfo: res.Data});

                let lastPath = storage.getStore('lastPath');
                this.$axios.get('/Api/Api/Web/GetWxUser/GetCIAM?openid=' + res.Data.Openid).then(res1 => {
                  if (res1.RetCode == '10000') {
                    if(lastPath == '/register') {
                      if(res1.Data) {
                        this.$router.push('/publicRegister');
                      } else {
                        this.$router.push('/register');
                      }
                    } else {
                      this.$router.push(lastPath);
                    }
                  }
                })
              }
              // let lastPath = storage.getStore('lastPath');
              // this.$router.push(lastPath);
            } else {
              this.RetMsg = res.RetMsg;
              this.$toast(res.RetMsg);
            }
          })
        }
      }
    } else {
      this.getAuthCode();
    }
  },
  methods: {
    ...mapActions(['setOpenid', 'setUserInfo']),
    getAuthCode() {
      // 测试链接：https://fx.tv-conf.com/authorize
      // qa链接：https://wcwechatqa.jjmc.cn/authorize
      //process.env.VUE_APP_REDIRECT_URL
      this.$axios.post('/Api/Api/Web/GetWxUser/GetCode?redirect='+ encodeURIComponent(process.env.VUE_APP_REDIRECT_URL) +'&scope=' + this.scope).then(res => {
        location.href = res.Data;
      })
    }
  }
}
</script>